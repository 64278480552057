import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

export const MedicalConditionPagination = ({ activePage, setActivePage, totalPages, midSize }) => {
    let pageIndexes = [];

    if (totalPages <= (midSize + 1)) {
        for (let i = 1; i <= totalPages; i++) {
            pageIndexes.push(i);
        }
    } else {
        while (pageIndexes.length <= midSize) {
            const startIndex = (activePage - midSize > 1) ? activePage - midSize : 1;
            const stopIndex = (activePage + midSize > totalPages) ? totalPages : activePage + midSize;

            for (let i = startIndex; i <= stopIndex; i++) {
                pageIndexes.push(i);
            }
        }
    }

    // const onClickFirst = useCallback(() => {
    //     setActivePage(1);
    // }, []);;

    const onClickPrev = useCallback(() => {
        setActivePage(activePage - 1);
    }, [activePage]);

    const onClickNext = useCallback(() => {
        setActivePage(activePage + 1);
    }, [activePage]);

    // const onClickLast = useCallback(() => {
    //     setActivePage(totalPages);
    // }, [activePage]);

    const onClickPageIndex = useCallback((index) => {
        setActivePage(index);
    }, []);

    return (
        <ul className="pagination custom">
            {/* <li className="pagination-item">
                <button className="pagination-btn" disabled={activePage === 1} onClick={onClickFirst}>
                    <span>« First</span>
                </button>
            </li> */}
            <li className="pagination-item previous">
                <button className="pagination-btn" disabled={activePage === 1} onClick={onClickPrev}>
                    <span>Previous</span>
                </button>
            </li>
            {
                pageIndexes.map((el) => (
                    <li key={el} className={`pagination-item ${el === activePage ? 'active' : ''}`}>
                        <button
                            className="pagination-btn"
                            disabled={el === activePage}
                            onClick={() => onClickPageIndex(el)}
                        >
                            {el}
                        </button>
                    </li>
                ))
            }
            <li className="pagination-item next">
                <button className="pagination-btn" disabled={activePage === totalPages} onClick={onClickNext}>
                    <span>Next</span>
                </button>
            </li>
            {/* <li className="pagination-item">
                <button className="pagination-btn" disabled={activePage === totalPages} onClick={onClickLast}>
                    <span>Last »</span>
                </button>
            </li> */}
        </ul>
    )
}

MedicalConditionPagination.propTypes = {
    activePage: PropTypes.number,
    setActivePage: PropTypes.func,
    totalPages: PropTypes.number,
    midSize: PropTypes.number
}

MedicalConditionPagination.defaultProps = {
    midSize: 4
}