import React, { useState, useEffect, useRef } from 'react';
import Autosuggest from 'react-autosuggest';
import { useDispatch, useSelector } from 'react-redux';
import { Image } from './Common/Image';
import { SvgIcon } from './Common/SvgIcon';
import { useMedicalConditions } from '../Hooks/MedicalConditions';
import Highlighter from "react-highlight-words";
import { getLowerCaseUrlParam, updateURLParameter } from '../utilities';
import { MedicalConditionPagination } from './Common/MedicalConditionPagination';
import { RichText } from '../richTextOptions';
const contentful = require('contentful');

const client = contentful.createClient({
    space: process.env.CONTENTFUL_SPACE_ID,
    accessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
});

// highlightIndex: Index of matched text
const Highlight = ({ children, highlightIndex }) => (
    <span className="suggestion-text-highlight">{children}</span>
);

const SectionConditionSearch = (props) => {
    const dispatch = useDispatch();
    const { searchTitle, subText, image, searchPlaceholder, displayResults, template } = props;
    let conditions = props.conditions;
    const [searchInputText, setSearchInputText] = useState('');
    const [suggestions, setSuggestions] = useState([]); // Suggestions of the autosuggest
    const [conditionResults, setConditionResults] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [firstSearched, setFirstSearched] = useState(false);
    let autoSuggestRef = useRef();
    const conditionsPerPage = 3;
    const totalPages = Math.ceil(conditionResults.length / conditionsPerPage);
    const offset = conditionsPerPage * (activePage - 1);
    const conditionsToDisplay = conditionResults.slice(offset, offset + conditionsPerPage);

    // conditions.forEach((condition, index) => {
    //     condition.conditionName = capitalizeFirstLetter(condition.conditionName.toLowerCase())
    // })

    const checkSuggestion = (searchValues, stringToCheck) => {
        return searchValues.some(el => stringToCheck.includes(el));
    }

    // Teach Autosuggest how to calculate suggestions for any given input value.
    const getSuggestions = (value, lengthToSearch = 0) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        let finalSuggestions = [];

        if (inputLength >= lengthToSearch) {
            if (conditions.length > 0) {
                let searchValues = [inputValue];
                const isSingleWord = !inputValue.includes(' ');
                const isPluralSingleWord = isSingleWord && inputValue.slice(-1) === 's';

                if (isSingleWord) {
                    if (isPluralSingleWord) {   // Plurals
                        searchValues.push(inputValue.slice(0, -1) + '(s)');
                        searchValues.push(inputValue.slice(0, -1));
                    }
                }

                let conditionNameSuggestions = [];
                let keyWordsSuggestions = [];

                conditions.forEach((el) => {
                    const conditionNameLowerCase = el.conditionName.toLowerCase();
                    const keyWordsLowerCase = el.keyWords.toLowerCase();

                    if (checkSuggestion(searchValues, conditionNameLowerCase)) {
                        conditionNameSuggestions.push(el);
                    } else if (checkSuggestion(searchValues, keyWordsLowerCase)) {
                        keyWordsSuggestions.push(el);
                    }
                });

                if (isSingleWord) {
                    // arrRule1,2,3,4 now are only applied for condition name
                    let arrRule1 = []; // Match exact in first position
                    let arrRule2 = []; // Match exact in second or third or ... position
                    let arrRule3 = []; // Match exact when including or excluding 's'
                    let arrRule4 = []; // The rest conditions
                    let categorizeCondition;

                    if (isPluralSingleWord) {
                        categorizeCondition = (condition) => {
                            // /[^\w\s]/gi: replace special characters
                            const splitString = condition.conditionName.replace(/[^\w\s]/gi, '').toLowerCase().split(' ');
                            let indexOfExactValue = splitString.indexOf(searchValues[0]);
                            indexOfExactValue = indexOfExactValue > -1 ? indexOfExactValue : splitString.indexOf(searchValues[1]);

                            if (indexOfExactValue === 0) {
                                arrRule1.push(condition);
                            } else if (indexOfExactValue > 0) {
                                arrRule2.push(condition);
                            } else if (splitString.indexOf(searchValues[2]) > -1) {
                                arrRule3.push(condition);
                            } else {
                                arrRule4.push(condition);
                            }
                        };
                    } else {
                        categorizeCondition = (condition) => {
                            // /[^\w\s]/gi: replace special characters
                            const splitString = condition.conditionName.replace(/[^\w\s]/gi, '').toLowerCase().split(' ');
                            let indexOfExactValue = splitString.indexOf(inputValue);

                            if (indexOfExactValue === 0) {
                                arrRule1.push(condition);
                            } else if (indexOfExactValue > 0) {
                                arrRule2.push(condition);
                            } else if (splitString.indexOf(inputValue + 's') > -1) {
                                arrRule3.push(condition);
                            } else {
                                arrRule4.push(condition);
                            }
                        };
                    }

                    conditionNameSuggestions.forEach(el => {
                        categorizeCondition(el);
                    });

                    finalSuggestions = [...arrRule1, ...arrRule2, ...arrRule3, ...arrRule4, ...keyWordsSuggestions];
                } else {
                    finalSuggestions = [...conditionNameSuggestions, ...keyWordsSuggestions];
                }
            }
        }

        return finalSuggestions;
    };

    // When suggestion is clicked, Autosuggest needs to populate the input
    // based on the clicked suggestion. Teach Autosuggest how to calculate the
    // input value for every given suggestion.
    const getSuggestionValue = (suggestion) => {
        // GDN: Group Display Name
        const displayName = suggestion.conditionName.trim();
        return displayName;
    }

    // Use your imagination to render suggestions.
    const renderSuggestion = (suggestion) => {
        const displayName = suggestion.conditionName.trim();

        const onClick = (e) => {
            e.preventDefault();
            setConditionResults(getSuggestions(displayName));
        }

        return (
            <>
                {
                    displayName && (
                        displayResults ? (
                            <a role="button" href="#" onClick={onClick}>
                                <Highlighter
                                    searchWords={[searchInputText]}
                                    autoEscape={true}
                                    textToHighlight={displayName}
                                    highlightTag={Highlight}
                                />
                            </a>
                        ) : (
                            <a href={`/whats-covered/search/?q=${displayName}`}>
                                <Highlighter
                                    searchWords={[searchInputText]}
                                    autoEscape={true}
                                    textToHighlight={displayName}
                                    highlightTag={Highlight}
                                />
                            </a>
                        )
                    )
                }
            </>
        );
    }

    const onChange = (event, { newValue }) => {
        setSearchInputText(newValue);
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value, 3));
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const searchIconClicked = (searchText = searchInputText) => {
        const trimSearchText = searchText.trim();
        if (trimSearchText.length > 0) {
            autoSuggestRef.current?.input?.blur?.(); // Make the Autosuggest lose its focus, so when after searching users don't have to click twice on anything

            if (!displayResults) {
                window.location.href = `/whats-covered/search/?q=${trimSearchText}`;
            }

            if (window.location.pathname === "/whats-covered/search/") {
                window.history.replaceState('', '', updateURLParameter(window.location.href, 'q', trimSearchText)); // Change URL "q" param
            }

            setConditionResults(getSuggestions(searchText, 1));
            setActivePage(1);
            setFirstSearched(true);
        }
    }

    const onKeyDown = (e) => {
        if (e.keyCode == '13') {
            searchIconClicked();
        }
    }

    const inputProps = {
        placeholder: searchPlaceholder || '',
        value: searchInputText,
        onChange: onChange,
        onKeyDown: onKeyDown,
        'aria-label': 'Medical conditions'
    };

    useEffect(() => {
        if (typeof window !== 'undefined' && conditions.length > 0) {
            let searchText = getLowerCaseUrlParam(window.location.href)['q'];

            if (searchText) {
                searchText = decodeURIComponent(searchText);
                setSearchInputText(searchText);
                searchIconClicked(searchText);
            }
        }
    }, [conditions]);

    const handleClickCategory = (category) => {
        dispatch({ type: 'HANDLE_CLICK_CATEGORY_SECTION_ALL_MEDICAL_CONDITIONS', payload: category });
        const allMedicalConditionsElement = document.getElementById('all-medical-conditions');

        if (allMedicalConditionsElement) {
            allMedicalConditionsElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleClickBodySystemOption = (bodySystem) => {
        dispatch({ type: 'HANDLE_CLICK_BODY_SYSTEM_OPTION_SECTION_ALL_MEDICAL_CONDITIONS', payload: bodySystem });
        const allMedicalConditionsElement = document.getElementById('all-medical-conditions');

        if (allMedicalConditionsElement) {
            allMedicalConditionsElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const Results = () => (
        <>
            {
                displayResults && firstSearched && (
                    <div className="condition-results">
                        <div className="wrap">
                            {
                                conditionsToDisplay.length > 0 ? (
                                    conditionsToDisplay.map((el, index) => (
                                        <div className="condition-item" key={index}>
                                            {el?.conditionName && <p className="condition-name">{el.conditionName.charAt(0).toUpperCase() + el.conditionName.slice(1)}</p>}
                                            <p className="examples">
                                                <strong>Examples: </strong>{typeof el?.description === 'string' ? (el.description.charAt(0).toUpperCase() + el.description.slice(1)) : (el?.description?.description && (el.description.description.charAt(0).toUpperCase() + el.description.description.slice(1)))}
                                            </p>
                                            <div className="more-info">
                                                <span className="body-system">
                                                    <strong>Body system: </strong>
                                                    {
                                                        el?.bodySystem && (
                                                            <span className="cursor-pointer" tabIndex={0} onClick={() => handleClickBodySystemOption(el.bodySystem)}>
                                                                {el.bodySystem}
                                                            </span>
                                                        )
                                                    }
                                                </span>
                                                {
                                                    el?.categories && (
                                                        <span className="condition-type">
                                                            <strong>Condition type: </strong>
                                                            {
                                                                el?.categories?.map?.((category, index) => (
                                                                    <React.Fragment key={category}>
                                                                        <span className="cursor-pointer" tabIndex={0} onClick={() => handleClickCategory(category)}>
                                                                            {category}
                                                                        </span>
                                                                        {index !== el.categories.length - 1 && ', '}
                                                                    </React.Fragment >
                                                                ))
                                                            }
                                                        </span>
                                                    )
                                                }

                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className="not-found">We couldn’t find the condition you’re looking for. It’s possible that we don’t cover it, or it might be listed using different terms. You can view the full list of pet health conditions below.</p>
                                )
                            }
                        </div>
                        {
                            firstSearched && conditionsToDisplay.length > 0 && totalPages > 1 && (
                                <MedicalConditionPagination
                                    activePage={activePage}
                                    setActivePage={setActivePage}
                                    totalPages={totalPages}
                                    midSize={2}
                                />
                            )
                        }
                    </div>
                )
            }
        </>
    )

    return (
        <section className="condition-search">
            {
                template === 'Image right, title on top' && (
                    <div className="container img-right-title-top">
                        {searchTitle && <RichText data={searchTitle}></RichText>}
                        <div className="row">
                            <div className="col-md-8">
                                <div className="search-wrapper">
                                    {subText && <p>{subText}</p>}
                                    <div className="custom-search-input">
                                        <Autosuggest
                                            ref={autoSuggestRef}
                                            suggestions={suggestions}
                                            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={onSuggestionsClearRequested}
                                            getSuggestionValue={getSuggestionValue}
                                            renderSuggestion={renderSuggestion}
                                            inputProps={inputProps}
                                        />
                                        <div className={`search-icon ${(searchInputText.trim()) ? 'cursor-pointer' : ''}`} onClick={() => searchIconClicked()}>
                                            <SvgIcon type='search' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="img-wrapper">
                                    {image && <Image image={image}></Image>}
                                </div>
                            </div>
                        </div>
                        <Results />
                    </div>
                )
            }
            {
                template === 'Image left' && (
                    <div className="container">
                        <div className="wrapper">
                            <div className="row">
                                <div className="col-lg-5"></div>
                                <div className="col-lg-7">
                                    <div className="search-wrapper">
                                        {searchTitle && <RichText data={searchTitle}></RichText>}
                                        {subText && <p>{subText}</p>}
                                        <div className="custom-search-input">
                                            <Autosuggest
                                                suggestions={suggestions}
                                                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                                onSuggestionsClearRequested={onSuggestionsClearRequested}
                                                getSuggestionValue={getSuggestionValue}
                                                renderSuggestion={renderSuggestion}
                                                inputProps={inputProps}
                                            />
                                            <div className={`search-icon ${(searchInputText.trim()) ? 'cursor-pointer' : ''}`} onClick={() => searchIconClicked()}>
                                                <SvgIcon type='search-condition' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="img-wrapper">
                                {image && <Image image={image}></Image>}
                            </div>
                            <Results />
                        </div>
                    </div>
                )
            }
        </section>
    )
}

const ContentfulSectionConditionSearch = (props) => {
    const isPreview = useSelector(state => state.app.isPreview);
    const [conditions, setConditions] = useState([]);
    const graphQLConditions = useMedicalConditions();

    useEffect(() => {
        if (isPreview) {
            client.getEntries({
                'content_type': 'medicalCondition',
                order: 'fields.conditionName',
                limit: 1000
            })
                .then((response) => {
                    if (response.items.length > 0) {
                        let newConditions = response.items.map(el => ({ ...el.fields }));
                        setConditions(newConditions);
                    }
                })
                .catch(console.error)
        } else {
            let newConditions = graphQLConditions.map(el => ({ ...el.node, keyWords: el.node.keyWords.keyWords }));
            setConditions(newConditions);
        }
    }, []);

    return (
        <SectionConditionSearch {...props} conditions={conditions} />
    )
}

export default ContentfulSectionConditionSearch;
