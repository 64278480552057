import { useStaticQuery, graphql } from "gatsby";

export const useMedicalConditions = () => {
    const { allContentfulMedicalCondition } = useStaticQuery(
        graphql`
            query {
                allContentfulMedicalCondition(sort: {order: ASC, fields: contentName}) {
                    edges {
                        node {
                            conditionName
                            contentName
                            contentful_id
                            keyWords {
                                keyWords
                            }
                            categories
                            description {
                                description
                            }
                            bodySystem
                        }
                    }
                }
            }
        `
    )

    return allContentfulMedicalCondition.edges;
}
